<template>
	<!-- 预出库单 -->
	<div class="container">

		<div class="search">
			<div class="search-l">
				<div class="l-item" style="width: 40%;">
					<div class="item-label">配货日期:</div>
					<div class="item-input" style="width: 80%;">
						<el-date-picker v-model="dateVal" type="daterange" range-separator="至" start-placeholder="开始日期"
							end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 100%;">
						</el-date-picker>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">配货仓库:</div>
					<div class="item-input">
						<el-select v-model="searchForm.warehouse_id" placeholder="请选择配货仓库"
							@change="changeRoutePlanWarehouse">
							<el-option v-for="item in warehouseList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">配送起点:</div>
					<div class="item-input">
						<el-select v-model="searchForm.delivery_line_id" placeholder="请选择配送路线">
							<el-option v-for="item in routeList" :key="item.id" :label="item.title" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">重新计算:</div>
					<div class="item-input">
						<el-radio-group v-model="searchForm.force">
							<el-radio :label="0">不重新计算</el-radio>
							<el-radio :label="1">重新计算路线</el-radio>
						</el-radio-group>
					</div>
				</div>
				<div class="l-item">
					<el-button type="warning"
						@click.stop="loading = true, page = 1, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], searchForm.force = 0, getList()">搜索</el-button>
					<el-button
						@click.stop="loading = true, page = 1, pageNum = 10, searchForm = {}, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], searchForm.force = 0 ,getList()">重置</el-button>
				</div>
				<i></i>
				<i></i>
				<i></i>
				<i></i>
			</div>
		</div>
		<div class="table">
			<div class="dialog-l">
				<div style="position: relative;">
					<div class="l-select">
						<p>行车路径：</p>
						<el-select v-model="routePlannType" placeholder="请选择行车路径" @change="changeRoutePlann">
							<el-option v-for="item in routePlannList" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
					<div class="qrcode-range">
						<div id="qrcode" ref="qrcode" style="padding:10px">

						</div>
						<span style="padding-bottom:10px;font-size:14px">微信扫一扫</span>
					</div>
				</div>
				<div id="mapContainer" style="width:100%; height:93%"></div>
			</div>


			<div class="dialog-r">
				<el-tabs value="normalList" style="max-height:800px">
					<el-tab-pane :label="normalName" name="normalList">
						<div class="r-item">
							<div class="item-title"><span
									style="color:green;font-weight: 600;">起点</span>：{{ searchForm.warehouse_name }}
							</div>
						</div>
						<div class="r-item" v-for="(item, index) in routePlannType == 1 ? mapData.short : mapData.fast"
							:key="index">
							<div class="item-title"><span style="color:blue;font-weight: 600;">第{{ index + 1 }}站
								</span>：{{ item.contacts }}
								{{ item.phone }}
							</div>
							<div class="item-address">地址：{{ item.address }}</div>
							<div class="item-address">共 {{ item.distance }} ，大约需要 {{ item.duration }}</div>
						</div>
						<div class="r-item">
							<div class="item-title" v-if="routePlannType == 1 "><span
									style="color:red;font-weight: 600;">结束</span>：总里程
								{{ mapData.totalShortDistance }} ，共需要 {{ mapData.totalShortDuration }}
							</div>
							<div class="item-title" v-else><span style="color:red;font-weight: 600;">结束</span>：总里程
								{{ mapData.totalFastDistance }} ，共需要 {{ mapData.totalFastDuration }}
							</div>
						</div>
					</el-tab-pane>
					<el-tab-pane :label="errName" name="errList">
						<div class="r-item" v-for="(item, index) in mapData.errList" :key="index">
							<div class="item-title"><span
									style="color:red;font-weight: 600;">第{{ index + 1 }}个</span>：{{ item.contacts }}
								{{ item.phone }}
							</div>
							<div class="item-address">地址：{{ item.address }}</div>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>

		</div>
	</div>

</template>

<script>
	import QRCode from 'qrcodejs2'
	import {
		getLodop
	} from '@/utils/CLodopfuncs.js'
	export default {
		data() {
			return {
				currentDateTime: this.getCurrentDateTime(),
				imageUrl: "https://static.tuanhuo.howtec.cc/images/logo.png",
				normalName: "配送站点",
				errName: "异常地址",
				dateVal: [],
				searchForm: {
					begin: '',
					end: '',
					force: 0,
					delivery_line_id: 0,
					warehouse_id: 0,
					delivery_type: 2,
					warehouse_name: ''
				},
				loading: true,
				page: 1,
				pageNum: 10,
				total: 0,
				orderDate: '',
				warehouseList: [], //仓库
				routeList: [], //配送路线
				// 供应商信息
				supplier_info: {},
				// 选择表格的数据
				selectionList: [],
				exportDisabled: false, //打印禁用
				operationDisabled: false, //批量确认、取消禁用
				// 路线规划
				mapData: {
					short: [],
					fast: []
				},
				routePlannType: 1,
				routePlannList: [{
						id: 1,
						name: '最短距离'
					},
					{
						id: 2,
						name: '最快时间'
					}
				],
				fullscreenLoading: false,
				mapKey: "",
				qrcode: null,

				//打印纸
				pageType: 1,
				pageTypeItem: {
					id: 1,
					name: '针式三等分多联纸',
					width: 2410,
					height: 930
				},
				pageTypeList: [{
					id: 1,
					name: '针式三等分多联纸',
					width: 2410,
					height: 930
				}, {
					id: 2,
					name: '针式二等分多联纸',
					width: 2410,
					height: 1400
				}, {
					id: 3,
					name: '针式一等分多联纸',
					width: 2410,
					height: 2800
				}, {
					id: 4,
					name: '标准A4纸',
					width: 2100,
					height: 2970
				}],
				printMode: 1
			}
		},
		mounted() {
			this.supplier_info = JSON.parse(sessionStorage.getItem('supplier_info'))
			// 时间
			this.dateVal = [this.getCurrentDate(), this.getCurrentDate()]
			this.searchForm.begin = this.dateVal[0]
			this.searchForm.end = this.dateVal[1]
			this.searchForm.force = 0
			// 路线
			this.getRouteList()
			// 仓库
			this.getWarehouse()
			// 检查是否有未配商品
			this.checkNoDelivery()

			this.pageType = localStorage.getItem("defaultPageType") ?? '1';
			this.pageType = Number(this.pageType)
			if (localStorage.getItem("defaultPageTypeItem")) {
				this.pageTypeItem = JSON.parse(localStorage.getItem("defaultPageTypeItem"));
			} else {
				this.pageTypeItem = {
					id: 1,
					name: '针式三等分多联纸',
					width: 2410,
					height: 930
				};
			}
		},
		methods: {
			changeRoutePlanWarehouse(id) {
				let that = this
				this.warehouseList.forEach((item, index) => {
					if (item.id == id) {
						that.searchForm.warehouse_name = item.name
					}
				})

			},
			jsonp_request(url) {
				var script = document.createElement('script');
				script.src = url;
				document.body.appendChild(script);
			},
			// 切换路线轨迹
			changeRoutePlann(e) {
				window.map.destroy()
				this.routePlannType = e
				this.routePlannConfirmFn()
			},
			selectPageType(value) {
				let that = this
				this.pageTypeList.forEach(item => {
					if (item.id == value) {
						that.pageTypeItem = item
					}
				})
				localStorage.setItem("defaultPageType", value);
				localStorage.setItem("defaultPageTypeItem", JSON.stringify(that.pageTypeItem));
			},
			checkNoDelivery() {
				this.$http.post(`erp/v1/preck/check_no_delivery`, {}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200 && data > 0) {

						this.$alert('今日有' + data + '件商品未生成配送单，请重新生成', '警告', {
							confirmButtonText: '确定',
							callback: action => {

							}
						});
					}
				});
			},
			GenerateQRCode() {
				if (this.qrcode == null) {
					this.qrcode = new QRCode(this.$refs.qrcode, {
						width: 128, // 二维码宽度，单位像素
						height: 128, // 二维码高度，单位像素
						text: "https://tuanhuo.howtec.cc/driving_line?key=" + this.mapData.driverKey + "&expire=" +
							this.mapData.driverKeyExpire, // 生成二维码的链接
					})
				} else {
					this.qrcode.makeCode("https://tuanhuo.howtec.cc/driving_line?key=" + this.mapData.driverKey +
						"&expire=" +
						this.mapData.driverKeyExpire)
				}

			},
			// 获取当前年月日
			getCurrentDate() {
				const today = new Date();
				let year = today.getFullYear();
				let month = (today.getMonth() + 1).toString().padStart(2, '0');
				let day = today.getDate().toString().padStart(2, '0');
				return `${year}-${month}-${day}`;
			},
			getList() {
				this.loading = true;
				this.searchForm.delivery_type = 2
				this.$http.post(`/erp/v1/preck/lst_map`, {
					pre_ckd_ids: '',
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.loading = false;
						if (data == undefined || data == null || data.length == 0) {
							this.$message.success(msg);
							return;
						}
						this.mapData = data
						this.mapKey = data.mapKey
						// 地图轨迹
						let geometries = []
						let routePlannArr = []
						//地图置空
						if (window.map) {
							window.map.destroy()
						}
						if (this.qrcode) {
							this.qrcode.clear()
							this.clearQRCode()
						}

						if (this.mapData.short.length == 0) {
							this.$message.error("没有匹配的数据");
							return;
						}

						if (this.routePlannType == 1) {
							routePlannArr = this.mapData.short
						} else {
							routePlannArr = this.mapData.fast
						}

						this.normalName = "配送站点（" + data.short.length + "）"
						this.errName = "异常地址（" + data.errList.length + "）"
						// 结束位置
						let end = routePlannArr[routePlannArr.length - 1]
						// 途经点
						let passingPoint = routePlannArr.map(i => {
							return `${i.lat}` + ',' + `${i.lng};`;
						}).join('');
						passingPoint = passingPoint.substring(0, passingPoint.lastIndexOf(';'));

						//起点
						geometries.push({
							styleId: 'start',
							position: new TMap.LatLng(this.mapData.start.lat, this.mapData.start
								.lng),
							content: this.mapData.start.name
						})
						routePlannArr.forEach((item, index) => {
							if (index < routePlannArr.length - 1) {
								geometries.push({
									styleId: 'marker',
									position: new TMap.LatLng(item.lat, item.lng),
									content: '第' + (index + 1) + '站'
								})
							}

						})
						//终点
						geometries.push({
							styleId: 'end',
							position: new TMap.LatLng(routePlannArr[routePlannArr.length - 1].lat,
								routePlannArr[routePlannArr.length - 1].lng),
							content: '第' + (routePlannArr.length) + '站'
						})
						// 地图
						this.$nextTick(() => {
							this.GenerateQRCode()

							window.map = new TMap.Map('mapContainer', {
								center: new TMap.LatLng(this.mapData.start.lat, this.mapData.start
									.lng), //自己的定位纬度 经度,
								zoom: 12,
								disableDefaultUI: true,
							});
							var url =
								`https://apis.map.qq.com/ws/direction/v1/driving/?from=${this.mapData.start.lat},${this.mapData.start.lng}&to=${end.lat},${end.lng}&output=jsonp&waypoints=${passingPoint}&callback=cb&key=${this.mapKey}`;
							//发起JSONP请求，获取路线规划结果
							this.jsonp_request(url);
							var marker = new TMap.MultiMarker({
								id: 'marker-layer',
								map: map,
								styles: {
									marker: new TMap.MarkerStyle({
										width: 28,
										height: 28,
										direction: 'top',
										src: 'https://static.tuanhuo.howtec.cc/miniprogram/common/tujing.png'
									}),
									start: new TMap.MarkerStyle({
										width: 28,
										height: 28,
										direction: 'top',
										src: 'https://static.tuanhuo.howtec.cc/miniprogram/common/start.png'
									}),
									end: new TMap.MarkerStyle({
										width: 28,
										height: 28,
										direction: 'top',
										src: 'https://static.tuanhuo.howtec.cc/miniprogram/common/end.png'
									}),
								},
								geometries: geometries,
							});
						})
					} else {
						this.$message.error(msg);
					}
				});
			},
			clearQRCode() {
				var qrcodeContainer = document.getElementById("qrcode");
				while (qrcodeContainer.hasChildNodes()) { // 清空所有子节点
					qrcodeContainer.removeChild(qrcodeContainer.lastChild);
				}
			},
			// 路线规划确定
			routePlannConfirmFn() {

				if (!this.searchForm.warehouse_id) {
					this.$message.error('请选择配送仓库');
					return
				}

				if (!this.searchForm.delivery_line_id) {
					this.$message.error('请选择配送路线');
					return
				}
				this.loading = true;

				this.$http.post(`/erp/v1/preck/lst_map`, {
					pre_ckd_ids: '',
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.loading = false;
						if (data == undefined || data == null || data.length == 0) {
							this.$message.success(msg);
							return;
						}
						this.mapData = data
						this.mapKey = data.mapKey

						// 地图轨迹
						let geometries = []
						let routePlannArr = []
						if (this.mapData.short.length == 0) {
							this.$message.error("没有匹配的数据");
							return;
						}

						if (this.routePlannType == 1) {
							routePlannArr = this.mapData.short
						} else {
							routePlannArr = this.mapData.fast
						}
						this.normalName = "配送站点（" + data.short.length + "）"
						this.errName = "异常地址（" + data.errList.length + "）"
						// 结束位置
						let end = routePlannArr[routePlannArr.length - 1]
						// 途经点
						let passingPoint = routePlannArr.map(i => {
							return `${i.lat}` + ',' + `${i.lng};`;
						}).join('');
						passingPoint = passingPoint.substring(0, passingPoint.lastIndexOf(';'));

						//起点
						geometries.push({
							styleId: 'start',
							position: new TMap.LatLng(this.mapData.start.lat, this.mapData.start
								.lng),
							content: this.mapData.start.name
						})
						routePlannArr.forEach((item, index) => {
							if (index < routePlannArr.length - 1) {
								geometries.push({
									styleId: 'marker',
									position: new TMap.LatLng(item.lat, item.lng),
									content: '第' + (index + 1) + '站'
								})
							}

						})
						//终点
						geometries.push({
							styleId: 'end',
							position: new TMap.LatLng(routePlannArr[routePlannArr.length - 1].lat,
								routePlannArr[routePlannArr.length - 1].lng),
							content: '第' + (routePlannArr.length) + '站'
						})
						// 地图
						this.$nextTick(() => {
							this.GenerateQRCode()

							window.map = new TMap.Map('mapContainer', {
								center: new TMap.LatLng(this.mapData.start.lat, this.mapData.start
									.lng), //自己的定位纬度 经度,
								zoom: 12,
								disableDefaultUI: true,
							});
							var url =
								`https://apis.map.qq.com/ws/direction/v1/driving/?from=${this.mapData.start.lat},${this.mapData.start.lng}&to=${end.lat},${end.lng}&output=jsonp&waypoints=${passingPoint}&callback=cb&key=${this.mapKey}`;
							//发起JSONP请求，获取路线规划结果
							this.jsonp_request(url);
							var marker = new TMap.MultiMarker({
								id: 'marker-layer',
								map: map,
								styles: {
									marker: new TMap.MarkerStyle({
										width: 28,
										height: 28,
										direction: 'top',
										src: 'https://static.tuanhuo.howtec.cc/miniprogram/common/tujing.png'
									}),
									start: new TMap.MarkerStyle({
										width: 28,
										height: 28,
										direction: 'top',
										src: 'https://static.tuanhuo.howtec.cc/miniprogram/common/start.png'
									}),
									end: new TMap.MarkerStyle({
										width: 28,
										height: 28,
										direction: 'top',
										src: 'https://static.tuanhuo.howtec.cc/miniprogram/common/end.png'
									}),
								},
								geometries: geometries,
							});
						})
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 所属路线
			getRouteList() {
				this.$http.post(`erp/v1/delivery_line/all`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.routeList = data
						this.addressRouteLine = data
						if (this.routeList.length > 0) {
							this.searchForm.delivery_line_id = this.routeList[0].id
						}
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 仓库
			getWarehouse() {
				this.$http.post(`erp/v1/warehouse/all`, {
					pageNum: 100
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.warehouseList = data
						if (this.warehouseList.length > 0) {
							this.searchForm.warehouse_id = this.warehouseList[0].id
						}

					} else {
						this.$message.error(msg);
					}
				});
			},
			// 获取当前年月日时分秒
			getCurrentDateTime() {
				const now = new Date();
				const year = now.getFullYear();
				const month = this.padNumber(now.getMonth() + 1); // 月份是从0开始的
				const day = this.padNumber(now.getDate());
				const hours = this.padNumber(now.getHours());
				const minutes = this.padNumber(now.getMinutes());
				const seconds = this.padNumber(now.getSeconds());
				return (
					year +
					'-' +
					month +
					'-' +
					day +
					' ' +
					hours +
					':' +
					minutes +
					':' +
					seconds
				);
			},
			padNumber(num) {
				return num < 10 ? '0' + num : num;
			},
		},
	}
</script>

<style lang="scss" scoped>
	.table {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: space-between;

		.dialog-l {
			width: 80%;
			height: 100%;

			.l-select {
				display: flex;
				align-items: center;
				margin-bottom: 10px;
			}
		}

		.dialog-r {
			width: 18%;
			display: flex;
			flex-direction: column;
			overflow-y: auto;

			.r-item {
				margin-bottom: 10px;
			}
		}
	}

	.qrcode-range {
		left: 20px;
		top: 80px;
		position: absolute;
		display: flex;
		background-color: #fff;
		z-index: 999;
		color: #000;
		flex-direction: column;
		text-align: center;
	}
</style>